<template>
  <div
    class="card card-profile company-card"
  >
    <!---->
    <div class="card-body position-relative">
      <!-- <b-dropdown
        v-if="self.isAdmin"
        no-caret
        right
        variant="link"
        class="position-absolute more-actions"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="20"
          />
        </template>

        <b-dropdown-item
          v-if="self.isAdmin"
          @click="$emit('showWalletDeposit', value.uuid)"
        >
          <feather-icon icon="DollarSignIcon" /> Wallet Deposit
        </b-dropdown-item>
      </b-dropdown> -->
      <div class="profile-image-wrapper">
        <div
          class="profile-image"
          :set="random = getRandomColor(value.name )"
        >
          <b-avatar
            button
            size="100"
            :variant="random.color"
            :text="random.character"
            class="align-baseline"
          />
        </div>
      </div>
      <h3
        class="text-ellipsis"
        :title="value.name"
      >
        {{ value.name }}
      </h3>
      <h6
        class="text-muted text-ellipsis"
        :title="value.address"
      >
        {{ value.address }}
      </h6>
      <hr class="mb-2">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h6 class="text-muted font-weight-bolder">
            Total Users
          </h6>
          <h3 class="mb-0">
            {{ value.users.total }}
          </h3>
        </div>
        <div>
          <h6 class="text-muted font-weight-bolder">
            Projects
          </h6>
          <h3 class="mb-0">
            {{ value.projects.total }}
          </h3>
        </div>
        <div>
          <h6 class="text-muted font-weight-bolder">
            Start Day
          </h6>
          <h3 class="mb-0">
            {{ value.weekStartDay }}
          </h3>
        </div>
        <div>
          <h6 class="text-muted font-weight-bolder">
            Tracking Type
          </h6>
          <h3 class="mb-0">
            {{ value.trackingType || 'N/A' }}
          </h3>
        </div>
      </div>
      <div class="d-flex pt-2 justify-content-center">
        <b-button
          v-b-tooltip.top="'Projects of company'"
          variant="outline-success"
          @click="goToCompany"
        >
          <feather-icon icon="InfoIcon" /> Projects
        </b-button>
        <b-button
          v-if="permissions.hasCompanyEditPermission"
          v-b-tooltip.top="'Edit company'"
          variant="outline-primary"
          class="ml-2"
          @click="$emit('editCompany',value)"
        >
          <feather-icon icon="EditIcon" /> Edit
        </b-button>
        <b-button
          v-if="permissions.hasInvitePermission"
          v-b-modal.invite-user-modal
          v-b-tooltip.top="'Invite user to company'"
          variant="outline-success"
          class="ml-2"
          @click="$emit('inviteUser',value)"
        >
          <feather-icon icon="UserPlusIcon" /> Invite
        </b-button>
      </div>
    </div>
    <!-- <project-lists
      :company-id="value.uuid"
      :title="value.name"
    /> -->
    <!----><!---->
  </div>
</template>
<script>
import {
  BAvatar, BButton,
  // BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
// import ProjectLists from '@/views/Settings/company/projects/ProjectList.vue'

export default {
  components: {
    BAvatar,
    BButton,
    // BDropdown,
    // BDropdownItem,
    // ProjectLists,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    permissions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      self: getUserData(),
    }
  },
  methods: {
    goToCompany() {
      this.$router.push({
        name: 'companies-projects',
        params: {
          uid: this.value.uuid,
          hasInvitePermission: this.permissions.hasInvitePermission,
        },
      })
    },
  },
}
</script>
<style scoped>
.text-ellipsis{
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.more-actions{
  top: 12px;
  right: 18px;
}
</style>

<style>
.dark-layout .company-card .btn-link {
  color: #f1f1f1 !important;
}
</style>
